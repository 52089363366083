import React, { useState, useRef } from "react";
import { Link as GatsbyLink } from "gatsby";
import {
  Box,
  Text,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button,
  Textarea,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Select,
  HStack,
  RadioGroup,
  Radio,
  Center,
  Checkbox,
  CheckboxGroup,
} from "@chakra-ui/react";
import { VscWarning } from "react-icons/vsc";
import Scarecrow from "../LottieLogos/Scarecrow";
import Turnstile from "react-turnstile";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
  //tickets: yup.string().required("Please select a ticket amount"),
  businessName: yup.string().required("Business name is required"),
  contactName: yup.string().required("Contact name is required"),
  contactEmail: yup
    .string()
    .email("Please enter a valid email")
    .required("Email is required"),
  phone: yup.string(),
  scarecrowName: yup.string().required("Scarecrow name is required"),
  businessAddress: yup.string().required("Business address is required"),
  mailingAddress: yup.string(),
});

const errorStyles = {
  color: "#bf1650",
  fontSize: "1rem",
  textAlign: "left",
};

function RequiredSpan() {
  return <span style={{ color: "#bf1650" }}>*</span>;
}

const VolunteerForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { isValid, errors },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const watchData = watch();
  // console.log(watchData)
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [turnstileResponse, setTurnstileReponse] = useState(false);

  const formRef = useRef(null);

  const onSubmit = async (data) => {
    setProcessing(true);
    // Add turnstileReponse to data object
    data = Object.assign({ turnstileResponse: turnstileResponse }, data);

    const JSONData = JSON.stringify(data);
    const formData = new URLSearchParams(data);

    const endpoint = `/.netlify/functions/send-scarecrow-registration`;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSONData,
    };

    await fetch(endpoint, options)
      .then((response) => {
        if (response.status === 200) {
          //console.log(JSON.stringify(data));
          setSucceeded(true);
          reset();
        } else {
          alert("Failed to validate CAPTCHA response. Please try again.");
          setProcessing(false);
        }
      })
      .then(
        fetch(
          `https://script.google.com/macros/s/${process.env.GATSBY_SCARECROW_APPSCRIPT_URL}/exec`,
          {
            method: "POST",
            body: formData,
          }
        ).then((res) => {
          if (res.status === 200) {
            console.log("success");
          } else {
            console.log("error");
            alert("Network error. Please try again.");
            setProcessing(false);
          }
        })
      );
  };

  const SuccessMessage = () => (
    <Box>
      <Alert
        status="success"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        borderRadius=".5rem"
      >
        <Box maxW="container.sm" px="12" mb="4">
          <Scarecrow />
        </Box>
        <AlertTitle mb="4">Registration submitted successfully!</AlertTitle>
        <AlertDescription maxWidth="lg">
          As a 501(c)(3) non-profit organization. We rely on the generosity of our community to provide our services. If you would like to make a donation, please click the
          button below. Thank you!
        </AlertDescription>
        <Box my="8" >
            <Button as={GatsbyLink} to="/donate"variant="primary" _hover={{color: 'white', bg: 'brand.400'}}>Donate Now!</Button>
          </Box>
      </Alert>
    </Box>
  );

  return (
    <>
    
      <Box w="100%" mb="24">
        {succeeded ? (
          <SuccessMessage />
        ) : (
          <>
            <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
              
              <Flex w="100%" flexWrap="wrap">
                <Box mb={8} pr={{ base: 0, lg: "2" }} w={{base: '100%', lg: '50%'}}>
                  <FormControl id="name">
                    <FormLabel>
                      Business Name
                      <RequiredSpan />
                    </FormLabel>
                    <Input
                      type="text"
                      {...register("businessName")}
                      autoComplete="on"
                    />
                    {errors.businessName && (
                      <HStack spacing="5px" style={errorStyles}>
                        <VscWarning /> <Box>{errors.businessName?.message}</Box>
                      </HStack>
                    )}
                  </FormControl>
                </Box>
                <Box mb={8} pr={{ base: 0, lg: "2" }} w={{base: '100%', lg: '50%'}}>
                  <FormControl id="name">
                    <FormLabel>
                      Contact Name
                      <RequiredSpan />
                    </FormLabel>
                    <Input
                      type="text"
                      {...register("contactName")}
                      autoComplete="on"
                    />
                    {errors.contactName && (
                      <HStack spacing="5px" style={errorStyles}>
                        <VscWarning /> <Box>{errors.contactName?.message}</Box>
                      </HStack>
                    )}
                  </FormControl>
                </Box>
              </Flex>

              <Flex w="100%" flexWrap="wrap">
                <Box
                  w={{ base: "100%", lg: "50%" }}
                  mb={8}
                  pr={{ base: 0, lg: "2" }}
                >
                  <FormControl id="contactEmail">
                    <FormLabel>
                      Contact Email
                      <RequiredSpan />
                    </FormLabel>
                    <Input
                      type="email"
                      {...register("contactEmail")}
                      autoComplete="on"
                    />
                    {errors.contactEmail && (
                      <HStack spacing="5px" style={errorStyles}>
                        <VscWarning /> <Box>{errors.contactEmail?.message}</Box>
                      </HStack>
                    )}
                  </FormControl>
                </Box>
                <Box w={{ base: "100%", lg: "50%" }} mb={8}>
                  <FormControl id="phone">
                    <FormLabel>Phone Number</FormLabel>
                    <Input
                      type="text"
                      {...register("phone", {
                        pattern: {
                          value:
                            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i,
                        },
                      })}
                      autoComplete="on"
                    />
                    {errors.phone && (
                      <Text color="#bf1650">
                        Please enter a valid phone number.
                      </Text>
                    )}
                  </FormControl>
                </Box>
              </Flex>

              <Box
                  mb={8}
                  pr={{ base: 0, lg: "2" }}
                >
                  <FormControl id="scarecrowName">
                    <FormLabel>
                      Scarecrow Name
                      <RequiredSpan />
                    </FormLabel>
                    <Input
                      type="text"
                      {...register("scarecrowName")}
                      autoComplete="on"
                    />
                    {errors.scarecrowName && (
                      <HStack spacing="5px" style={errorStyles}>
                        <VscWarning /> <Box>{errors.scarecrowName?.message}</Box>
                      </HStack>
                    )}
                  </FormControl>
                </Box>

                <Box
                  mb={8}
                  pr={{ base: 0, lg: "2" }}
                >
                  <FormControl id="Business Address">
                    <FormLabel>
                      Business Address
                      <RequiredSpan />
                    </FormLabel>
                    <Input
                      type="text"
                      {...register("businessAddress")}
                      autoComplete="on"
                    />
                    {errors.businessAddress && (
                      <HStack spacing="5px" style={errorStyles}>
                        <VscWarning /> <Box>{errors.businessAddress?.message}</Box>
                      </HStack>
                    )}
                  </FormControl>
                </Box>

                <Box
                  mb={8}
                  pr={{ base: 0, lg: "2" }}
                >
                  <FormControl id="Mailing Address">
                    <FormLabel>
                      Mailing Address if different from Business Address
                    </FormLabel>
                    <Input
                      type="text"
                      {...register("mailingAddress")}
                      autoComplete="on"
                    />
                    {errors.mailingAddress && (
                      <HStack spacing="5px" style={errorStyles}>
                        <VscWarning /> <Box>{errors.mailingAddress?.message}</Box>
                      </HStack>
                    )}
                  </FormControl>
                </Box>

                <Box>
                <FormControl id="mailinglist" mb={8} as="fieldset">
                  <FormLabel as="legend">
                    I will display at Mugler Plaza
                    <RequiredSpan />
                  </FormLabel>
                  <RadioGroup defaultValue="no">
                    <HStack spacing="4">
                      <Radio value="yes" {...register("displayMunger")}>
                        Yes
                      </Radio>
                      <Radio value="no" {...register("displayMunger")}>
                        No
                      </Radio>
                    </HStack>
                  </RadioGroup>
                </FormControl>
              </Box>

              <Flex w="100%" flexWrap="wrap">
                <Box
                  w={{ base: "100%", lg: "50%" }}
                  mb={8}
                  pr={{ base: 0, lg: "2" }}
                >
                  <FormControl id="instagramTag">
                    <FormLabel>
                      Instagram Tag
                    </FormLabel>
                    <Input
                      type="text"
                      {...register("instagram")}
                      autoComplete="on"
                    />
                  </FormControl>
                </Box>
                <Box w={{ base: "100%", lg: "50%" }} mb={8}>
                  <FormControl id="facebookTag">
                    <FormLabel>Facebook Tag</FormLabel>
                    <Input
                      type="text"
                      {...register("facebook")}
                      autoComplete="on"
                    />
                  </FormControl>
                </Box>
              </Flex>

              <Box>
                <FormControl id="questions" mb={8}>
                  <FormLabel>
                    Questions?
                  </FormLabel>
                  <Textarea placeholder="" {...register("questions")} />
                  {errors.questions && (
                    <HStack spacing="5px" style={errorStyles}>
                      <VscWarning /> <Box>{errors.questions?.message}</Box>
                    </HStack>
                  )}
                </FormControl>
              </Box>
              
              <Center mb="8">
                <Turnstile
                  sitekey={process.env.GATSBY_TURNSTILE_SITE_KEY}
                  theme="light"
                  onVerify={(token) => setTurnstileReponse(token)}
                />
              </Center>

              {/* <Box>
                <FormControl id="mailinglist" mb={8} as="fieldset">
                  <FormLabel as="legend">
                    Would you like to make a donation?
                  </FormLabel>
                  <CheckboxGroup>
                    <HStack spacing="4">
                      <Checkbox value="yes" {...register("donation")}>
                        Yes
                      </Checkbox>
                    </HStack>
                  </CheckboxGroup>
                </FormControl>
              </Box> */}

             <Box mt="4">
                <Button
                  className="submit"
                  variant="primary"
                  disabled={processing}
                  type="submit"
                  mt={4}
                  isLoading={processing}
                  loadingText="Submitting"
                >
                  Submit
                </Button>
              </Box>
            </form>
          </>
        )}
      </Box>
    </>
  );
};

export default VolunteerForm;
